import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", null, [_withDirectives(_createElementVNode("input", {
    placeholder: "用户名",
    type: "text",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.username = $event)
  }, null, 512), [[_vModelText, $data.username]]), _withDirectives(_createElementVNode("input", {
    placeholder: "密码",
    type: "text",
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.password = $event)
  }, null, 512), [[_vModelText, $data.password]]), _createElementVNode("button", {
    onClick: _cache[2] || (_cache[2] = (...args) => $options.loginHandle && $options.loginHandle(...args))
  }, "登录"), _createElementVNode("button", {
    onClick: _cache[3] || (_cache[3] = (...args) => $options.logoutHandle && $options.logoutHandle(...args))
  }, "退出登录")]);
}