import { Picture as IconPicture } from '@element-plus/icons-vue';
export default {
  name: "ProductCenterComponent",
  components: {
    IconPicture
  },

  data() {
    return {
      showPage: false,
      //产品种类列表
      ProductCategoryList: [],
      // ProductList:[],
      //axios获得的产品列表
      ProductCenterList: [],
      //暂存总产品
      ProductAll: [],
      Product: [],
      //数据总数
      totalData: null,
      //当前页面
      currentPage: 1,
      //每页展示数据数量
      pageSize: 9,
      list: [],
      selectedCategoryDetailId: null
    };
  },

  methods: {
    selectAllProduct() {
      this.ProductCenterList = this.ProductAll;
      this.totalData = this.ProductCenterList.length;
      this.handleCurrentChange(1);
    },

    lookDetail(selectedId) {
      this.$router.push({
        name: "pcDetail",
        params: {
          selectedProductId: selectedId
        }
      });
    },

    //根据选择的种类获取
    getProductByCategory(categoryId) {
      console.log("======sss=========");
      console.log(this.ProductAll);
      this.Product = [];
      let myData = JSON.parse(JSON.stringify(this.ProductAll));

      for (let i = 0; i < myData.length; i++) {
        if (myData[i].category == categoryId) {
          this.Product.push(myData[i]);
        }
      }

      console.log(this.Product);
      this.ProductCenterList = this.Product;
      this.totalData = this.ProductCenterList.length;
      this.handleCurrentChange(1);
    },

    //分页
    handleCurrentChange(cpag) {
      this.currentPage = cpag;
      this.list = this.ProductCenterList.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize);
    }

  },
  computed: {
    sliceList() {
      return function (imageUrlText, number) {
        let index = 0;
        let newArray = [];

        while (index < imageUrlText.length) {
          newArray.push(imageUrlText.slice(index, index += number));
        }

        return newArray;
      };
    }

  },

  async mounted() {
    //获取产品种类
    await this.$api.getProductCategoryList().then(res => {
      this.ProductCategoryList = res.data; // console.log("===============")

      console.log(res.data);
    }).catch(error => {
      console.log(error.response);
    });
    this.selectedCategoryDetailId = this.$route.params.selectedCategoryId;

    if (this.selectedCategoryDetailId) {
      console.log("------选中产品id------");
      console.log(this.selectedCategoryDetailId);
      console.log("------选中产品idend------"); //获取全部产品

      this.$api.getProductCenterList().then(res => {
        this.ProductCenterList = res.data;
        this.ProductAll = res.data; //数据总条数

        this.totalData = this.ProductCenterList.length;
        console.log('------centerlist-------');
        console.log(this.ProductAll); //根据类别筛选产品

        this.getProductByCategory(this.selectedCategoryDetailId);
        this.showPage = true;
      }).catch(error => {
        console.log(error.response);
      }); //this.getProductByCategory(this.selectedCategoryDetailId)
    } else {
      //获取全部产品
      this.$api.getProductCenterList().then(res => {
        this.ProductCenterList = res.data;
        this.ProductAll = res.data;
        console.log(res.data);
        this.totalData = this.ProductCenterList.length;
        this.handleCurrentChange(this.currentPage);
        this.showPage = true;
      }).catch(error => {
        console.log(error.response);
      });
    }
  }

};