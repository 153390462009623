export default {
  name: "CommonImg",
  components: {},

  data() {
    return {
      imageArray: [require("@/assets/common/01.png"), require("@/assets/common/02.png"), require("@/assets/common/03.png"), require("@/assets/common/032.png"), require("@/assets/common/043.png"), require("@/assets/common/05.png")]
    };
  }

};