// @ is an alias to /src
import CompanyProfileComponent from "@/components/pc/CompanyProfileComponent";
import ApplicationCaseComponent from "@/components/pc/ApplicationCaseComponent";
import { Picture as IconPicture } from '@element-plus/icons-vue';
export default {
  name: 'Home',
  components: {
    CompanyProfileComponent,
    ApplicationCaseComponent,
    IconPicture
  },

  data() {
    return {
      showPage: false,
      //轮播图列表
      banner_list: [],
      // 图片父容器高度
      bannerHeight: 500,
      // 浏览器宽度
      screenWidth: 0,
      ProductCategoryList: []
    };
  },

  methods: {
    setSize: function () {
      // 通过浏览器宽度(图片宽度)计算高度
      this.bannerHeight = 500 / 1920 * this.screenWidth;
    },

    lookDetail(selectedId) {
      console.log('homelook:   ' + selectedId);
      this.$router.push({
        name: "pcInfo",
        params: {
          selectedCategoryId: selectedId
        }
      });
    }

  },
  computed: {
    // this.setActiveItem(1),
    sliceList() {
      return function (imageUrlText, number) {
        let index = 0;
        let newArray = [];

        while (index < imageUrlText.length) {
          newArray.push(imageUrlText.slice(index, index += number));
        }

        return newArray;
      };
    }

  },

  async mounted() {
    // 首次加载时,需要调用一次
    this.screenWidth = window.innerWidth * window.devicePixelRatio;
    this.setSize(); // 窗口大小发生改变时,调用一次

    window.onresize = () => {
      this.screenWidth = window.innerWidth * window.devicePixelRatio;
      this.setSize();
    };

    console.log("mounted");
    await this.$api.getBannerList().then(res => {
      this.banner_list = res.data;
    }).catch(error => {
      console.log(error.response);
    }); //获取产品种类数据

    await this.$api.getProductCategoryList().then(res => {
      this.ProductCategoryList = res.data;
      this.showPage = true;
      console.log('homehome');
      console.log(this.ProductCategoryList);
    }).catch(error => {
      console.log(error.response);
    });
    this.$refs.carousel.setActiveItem(1);
  }

};