import CompanyProfileComponentM from "@/components/mobile/CompanyProfileComponentM";
import CompanyHistoryComponentM from "@/components/mobile/CompanyHistoryComponentM";
import HonorCertificationComponentM from "@/components/mobile/HonorCertificationComponentM";
export default {
  name: "AboutUsComponentM",
  components: {
    CompanyProfileComponentM,
    CompanyHistoryComponentM,
    HonorCertificationComponentM
  },

  data() {
    return {};
  }

};