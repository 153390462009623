export default {
  name: "ApplicationCaseComponentM",

  data() {
    return {
      showPage: false,
      showPagination: true,
      ApplicationCaseList: [],
      totalData: '',
      currentPage: 1,
      pageSize: 3,
      list: []
    };
  },

  methods: {
    lookDetail(selectedId) {
      this.$router.push({
        name: "acDetail",
        params: {
          selectedCaseIdM: selectedId
        }
      });
      this.$mybus.emit('isApplicationCase', true);
    },

    handleCurrentChange(cpag) {
      this.currentPage = cpag;
      this.list = this.ApplicationCaseList.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize);
    }

  },

  mounted() {
    //获取数据
    this.$api.getApplicationCaseList().then(res => {
      this.ApplicationCaseList = res.data;
      console.log(res.data);
      this.totalData = this.list.length;
      this.handleCurrentChange(this.currentPage);

      if (this.totalData <= this.pageSize) {
        this.showPagination = false;
      }

      this.showPage = true;
    }).catch(error => {
      console.log(error.response);
    });
  }

};