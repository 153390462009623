/**
 * 请求方法的统一处理
 */

import base from "./base";
import axios from "../utils/request";

const api = {
    // getMusicData(params){
    //     return axios.get(base.baseURL+base.musicPath,{
    //         params:params
    //     })
    // }
    /**
     * 登录接口
     */
    getLogin(params){
       return  axios.post(base.baseURL+ base.login,params)
    },

    /**
     * 轮播图接口
     */
    getBannerList(){
      return axios.get(base.baseURL+base.banner)
    },

    /**
     * 企业简介接口
     */

    getComPanyProfileList(){
        return axios.get(base.baseURL+base.companyProfile)
    },

    /**
     * 企业历程接口
     */
    getCompanyHistoryList(){
        return axios.get(base.baseURL+base.companyHistory)
    },

    /**
     * 荣誉资质接口
     */

    getHonorCertificationList(){
        return axios.get(base.baseURL+base.honorCertification)
    },

    /**
     * 荣誉资质详情接口
     */
    getHonorCertificationDetail(params){
        return axios.get(base.baseURL+base.honorCertification+params.id)
    },

    /**
     * 产品种类接口
     */
    getProductCategoryList(){
        return axios.get(base.baseURL+base.productCategory)
    },

    /**
     * 产品接口
     */
    getProductCenterList(){
        return axios.get(base.baseURL+base.productCenter)
    },
    /**
     * 产品详情接口
     */
    getProductDetailList(params){
        return axios.get(base.baseURL+base.productCenter+params.id)
    },

    /**
     * 根据产品种类获取应用案例
     */
    getProductByCategoryList(params){
        return axios.get(base.baseURL+base.productCenter+params.id+base.getProductByCategory)
    },

    /**
     * 根据产品获取产品详情
     */
    getProductIntroduceByPIdList(params){
        return axios.get(base.baseURL+base.getProductIntroduceByPIdList,params)
    },

    /**
     * 应用案例接口
     */
    getApplicationCaseList(){
        return axios.get(base.baseURL+base.applicationCase)
    },


    /**
     * 应用案例详情接口
     */
    getApplicationCaseDetail(params){
        return axios.get(base.baseURL+base.applicationCase+params.id)
    },

    /**
     * 联系我们接口
     */
    getContactUsList(){
        return axios.get(base.baseURL+base.contactUs)
    },

    /**
     * 在线询价详情
     */
    postOnlineInquiry(params){
        return axios.post(base.baseURL+base.onlineInquiry,params)
    }
}

export default api;

