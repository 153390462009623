export default {
  name: "Footer",

  data() {
    return {
      pageShow: false,
      brandImageUrl: require("@/assets/common/brand.png"),
      contactUS: ""
    };
  },

  mounted() {
    //获取数据
    this.$api.getContactUsList().then(res => {
      this.contactUS = res.data[0];
      console.log(res.data); //this.showPage = true

      this.pageShow = true;
    }).catch(error => {
      console.log(error.response);
    });
  }

};