import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from "axios";
import api from "@/api";

import mitt from "mitt"
// import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import Vant from 'vant';
import 'vant/lib/index.css';


import BaiduMap from 'vue-baidu-map-3x';



const app = createApp(App).use(store).use(router);
app.use(Vant);
// app.use(BaiduMap,{ak:"k3FgrSUPNR1jtnPMFj1PtrSGNfxZ2Rq3"});
app.use(BaiduMap, {
    // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
    ak: 'k3FgrSUPNR1jtnPMFj1PtrSGNfxZ2Rq3'
});
// app.use(Message)

app.config.globalProperties.$axios = axios;
app.config.globalProperties.$api = api;
app.config.globalProperties.$mybus = new mitt();//挂载全局属性
// app.config.globalProperties.$message = Message;

app.mount("#app");

axios.defaults.withCredentials = false; //阻止ajax附带cookie
