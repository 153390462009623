// import Header from "@/components/pc/header";
import Header from "@/components/pc/Header";
import HeaderNav from "@/components/pc/header-nav";
import Footer from "@/components/pc/Footer";
export default {
  name: "Layout",

  data() {
    return {
      isShow: false
    };
  },

  components: {
    Header,
    HeaderNav,
    Footer
  },

  mounted() {
    console.log("layoutmounted");
    let t = window.devicePixelRatio;
    document.body.style.zoom = 1 / t; //window.devicePixelRatio = 1.2
    // if(t>1){
    //   document.body.style.zoom = 1/t
    //   //window.devicePixelRatio = 1
    //  // this.isShow = true
    // }

    let t1 = window.devicePixelRatio;
    console.log("layoutmounted");
    console.log(t);
    console.log(); //console.log(t1)
  }

};