import { onBeforeUnmount, onMounted, onUnmounted } from "vue";
export default {
  name: "Nav",

  setup() {
    onMounted(() => {
      console.log('Component is mounted!');
    });
    onBeforeUnmount(() => {
      console.log("aaaaaaa");
    });
    onUnmounted(() => {
      console.log("asdas");
      localStorage.removeItem("selectedName");
    });
  },

  data() {
    return {
      active: '/home',
      myNav: [{
        title: '网站首页',
        path: '/home'
      }, {
        title: '关于我们',
        path: '/aboutUs'
      }, {
        title: '产品中心',
        path: '/productCenter'
      }, {
        title: '应用案例',
        path: '/applicationCase'
      }, {
        title: '联系我们',
        path: '/contactUs'
      }, {
        title: '在线询价',
        path: '/onlineInquiry'
      }],
      isProduct: null,
      isApplicationCase: null
    };
  },

  watch: {
    active(newVal) {
      switch (newVal) {
        case 0:
          this.$router.push('/home');
          break;

        case 1:
          this.$router.push('/aboutUs');
          break;

        case 2:
          this.$router.push('/productCenter');
          break;

        case 3:
          this.$router.push('/applicationCase');
          break;

        case 4:
          this.$router.push('/contactUs');
          break;

        case 5:
          this.$router.push('/onlineInquiry');
          break;
      }
    }

  },
  methods: {
    mySelectNav() {
      localStorage.setItem("selectedName", this.active);
    }

  },

  created() {
    //自动跳转到首页
    if (this.$route.path === '/') {
      this.$router.replace("/home");
    }
  },

  mounted() {
    this.$mybus.on('isProduct', val => {
      this.isProduct = val; //是否跳转产品中心

      if (this.isProduct) {
        localStorage.setItem("selectedName", '/productCenter');
        this.active = localStorage.getItem("selectedName");
      }
    });
    this.$mybus.on('isApplicationCase', val => {
      this.isApplicationCase = val; //是否跳转应用案例

      if (this.isApplicationCase) {
        localStorage.setItem("selectedName", '/applicationCase');
        this.active = localStorage.getItem("selectedName");
      }
    });
    console.log(localStorage.getItem("selectedName"));
    this.active = localStorage.getItem("selectedName");
    console.log("=========nav=======");
    console.log(localStorage.getItem("selectedName"));
  }

};