import { Picture as IconPicture } from '@element-plus/icons-vue';
export default {
  name: "ProductDetailCenterComponent",
  components: {
    IconPicture
  },

  data() {
    return {
      showPage: false,
      selectedProductDetailId: "",
      ProductDetail: [],
      ProductIntroduceList: []
    };
  },

  async mounted() {
    // this.$mybus.on("msg",selectedImageUrl=>{
    //   this.imageDetailUrl = selectedImageUrl;
    //   // console.log(this.imageDetailUrl);
    // })
    this.selectedProductDetailId = this.$route.params.selectedProductId;
    console.log(this.selectedProductDetailId); //获取产品数据

    await this.$api.getProductDetailList({
      id: this.selectedProductDetailId
    }).then(res => {
      this.ProductDetail = res.data; // console.log("===============")

      console.log(res.data);
    }).catch(error => {
      console.log(error.response);
    }); //获取产品介绍数据

    await this.$api.getProductIntroduceByPIdList({
      params: {
        PId: this.selectedProductDetailId
      }
    }).then(res => {
      this.ProductIntroduceList = res.data;
      console.log(res.data);
    }).catch(error => {
      console.log(error.response);
    });
    this.showPage = true;
  }

};