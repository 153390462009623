import CommonImgM from "@/components/mobile/commonImgM";
export default {
  name: "ProductDetail",
  components: {
    CommonImgM
  },

  data() {
    return {
      showPage: false,
      selectedAcDetailId: "",
      applicationCase: []
    };
  },

  methods: {
    onClickLeft() {
      history.back();
    }

  },

  mounted() {
    this.selectedAcDetailId = this.$route.params.selectedCaseIdM; // console.log(this.selectedAcDetailId);

    this.$api.getApplicationCaseDetail({
      id: this.selectedAcDetailId
    }).then(res => {
      this.applicationCase = res.data;
      console.log(this.applicationCase);
      this.showPage = true;
    }).catch(error => {
      console.log(error.response);
    });
  }

};