import { ImagePreview } from "vant";
export default {
  name: "HonorCertificationComponentM",

  data() {
    return {
      showPage: false,
      showPagination: true,
      list: [],
      honorCertificationList: [],
      totalData: null,
      currentPage: 1,
      pageSize: 3
    };
  },

  methods: {
    sceneImg(image) {
      let imageArray = [];
      imageArray.push(image);
      console.log(image);
      ImagePreview({
        images: imageArray,
        closeable: true,
        showIndex: false
      });
    },

    handleCurrentChange(cpag) {
      this.currentPage = cpag; // this.honorCertificationList = this.imageUrlText.slice((this.currentPage-1)*3,this.currentPage*3)

      this.list = this.honorCertificationList.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize);
    }

  },

  mounted() {
    this.$api.getHonorCertificationList().then(res => {
      console.log(res.data);
      this.honorCertificationList = res.data;
      this.totalData = this.honorCertificationList.length;
      this.handleCurrentChange(this.currentPage); //当数据只有一页时不显示分页

      if (this.totalData <= this.pageSize) {
        this.showPagination = false;
      }

      this.showPage = true;
      console.log(res.data);
    }).catch(error => {
      console.log(error.response);
    });
  }

};