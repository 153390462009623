import CommonImgM from "@/components/mobile/commonImgM";
import { Dialog, Notify } from "vant";
export default {
  name: "OnlineInquiry",
  components: {
    CommonImgM
  },

  data() {
    return {
      InquiryFormM: {
        name: "",
        company: "",
        telephone: "",
        product: "",
        remark: ""
      },
      rules: {
        name: [{
          required: true,
          message: "姓名不能为空",
          trigger: "blur"
        }],
        company: [{
          required: false,
          message: "公司名不能为空",
          trigger: "blur"
        }],
        telephone: [{
          required: true,
          message: "手机号不能为空",
          trigger: "blur"
        }, {
          pattern: /^\d{7,11}$/,
          message: '长度应该在7到11之间'
        } // { required:true, type: 'number', message: '必须为数字' },
        ],
        product: [{
          required: true,
          message: "意向产品不能为空",
          trigger: "blur"
        }],
        remark: [{
          required: false,
          message: "产品描述不能为空",
          trigger: "blur"
        }]
      }
    };
  },

  methods: {
    restFields() {
      this.InquiryFormM.name = null;
      this.InquiryFormM.company = null;
      this.InquiryFormM.telephone = null;
      this.InquiryFormM.product = null;
      this.InquiryFormM.remark = null;
    },

    onSubmit() {
      Dialog.confirm({
        title: '是否确认提交' // message: '是否确认提交',

      }).then(() => {
        // on confirm
        //axios路由
        this.$api.postOnlineInquiry(this.InquiryFormM).then(res => {
          console.log(res);
        }).catch(error => {
          console.log(error);
        });
        Notify({
          type: 'success',
          message: '提交成功'
        });
        this.restFields();
      }).catch(() => {
        // on cancel
        this.restFields();
      });
    },

    onReset() {// this.$refs.formRef.resetFields()
    }

  }
};