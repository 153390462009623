import { createRouter, createWebHashHistory } from 'vue-router'




import routerM from './routerM';
import routerPC from "@/router/routerPC";



const ISMOBILE = function () {
  let flag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  return flag;
  console.log(flag)
};



const router = createRouter({
  history: createWebHashHistory(),
  routes: ISMOBILE() ? routerM : routerPC,
})


router.beforeEach((to,from,next)=>{

  if(to.meta.isLogin){
    //token:令牌
    let token = localStorage.getItem("token");
    if(token){
      next()
    }else {
      next({
        path:"/login"
      })
    }
  }else {
    next();
  }
})

export default router
