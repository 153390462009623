import CommonImg from "@/components/CommonImg";
import CompanyProfileComponent from "@/components/pc/CompanyProfileComponent";
export default {
  name: "CompanyProfile",
  components: {
    CommonImg,
    CompanyProfileComponent
  } // data(){
  //   return{
  //     //公司简介内容
  //     companyProfileText:"株式会社NISSEI ECO自1970年10月创立以来，坚持不懈的进行自我挑战,满足客户的需求变化及无法预测的市场变化，继续秉持自给自足独立独步的精神，重视地球环境，尽全力制造出可信赖的产品。",
  //     //公司简介图片
  //     companyProfileUrl:require("@/assets/common/companyImage.png"),
  //   }
  // }

};