import CommonImgM from "@/components/mobile/commonImgM";
export default {
  name: "ProductDetail",
  components: {
    CommonImgM
  },

  data() {
    return {
      showPage: false,
      selectedProductDetailId: "",
      ProductDetail: "",
      ProductIntroduceList: []
    };
  },

  methods: {
    onClickLeft() {
      history.back();
    }

  },

  async mounted() {
    this.selectedProductDetailId = this.$route.params.selectedProductIdM;
    console.log(this.selectedProductDetailId);
    console.log(this.$route.params.selectedProductIdM); //获取产品数据

    await this.$api.getProductDetailList({
      id: this.selectedProductDetailId
    }).then(res => {
      this.ProductDetail = res.data;
      console.log("=======sss========");
      console.log(res.data);
    }).catch(error => {
      console.log(error.response);
    }); //获取产品介绍数据

    await this.$api.getProductIntroduceByPIdList({
      params: {
        PId: this.selectedProductDetailId
      }
    }).then(res => {
      this.ProductIntroduceList = res.data; // console.log("===============")

      console.log(res.data);
    }).catch(error => {
      console.log(error.response);
    });
    this.showPage = true;
  }

};