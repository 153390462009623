import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_image = _resolveComponent("el-image");

  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_image, {
    src: $data.imageArray[5],
    style: {
      "width": "100%",
      "height": "600px"
    }
  }, null, 8, ["src"])]);
}