import { Picture as IconPicture } from '@element-plus/icons-vue';
export default {
  name: "HonorCertificateDetailComponent",
  components: {
    IconPicture
  },

  data() {
    return {
      showPage: false,
      selectedImageDetailId: "",
      honorCertificationDetail: []
    };
  },

  created() {
    this.selectedImageDetailId = this.$route.params.selectedImageId; //console.log(this.selectedImageDetailId);
    //获取数据

    this.$api.getHonorCertificationDetail({
      id: this.selectedImageDetailId
    }).then(res => {
      this.honorCertificationDetail = res.data;
      this.showPage = true;
      console.log("------------");
      console.log(res.data);
    }).catch(error => {
      console.log(error.response);
    });
  }

};