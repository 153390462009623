import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-775358c6"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  style: {
    "flex": "0 0 auto"
  }
};
const _hoisted_2 = {
  style: {
    "flex": "1 0 auto"
  }
};
const _hoisted_3 = {
  style: {
    "flex": "0 0 auto"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Header = _resolveComponent("Header");

  const _component_HeaderNav = _resolveComponent("HeaderNav");

  const _component_router_view = _resolveComponent("router-view");

  const _component_Footer = _resolveComponent("Footer");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_Header)]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_HeaderNav), _createVNode(_component_router_view)]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_Footer)])], 64);
}