import { Picture as IconPicture } from '@element-plus/icons-vue';
export default {
  name: "ApplicationCaseDetailComponent",
  components: {
    IconPicture
  },

  data() {
    return {
      showPage: false,
      acTitle: "高新技术产业证书",
      imageDetailUrl: require("@/assets/honorCertificate/certificate01.png"),
      selectedAcDetailId: "",
      selectedAcDetailName: "",
      selectedAcDetailUrl: "",
      applicationDescription: "曼海姆SAP竞技场是运动竞技场演化的终极例子。作为一个在技术上设计独特的建筑，曼海姆SAP竞技场代表了转变的典范，它可以作为手球体育场，可以作为冰球场，还可以作为优雅的音乐厅，甚至广阔的策骑场地。这个叹为观止的建筑可以容纳多达15000名观众，相应地，针对数量庞大的观众，配备了杜拉维特斯达克3系列，确保尖端的设计和竞技场卫生场所的清洁卫生。",
      applicationCase: ""
    };
  },

  mounted() {
    this.selectedAcDetailId = this.$route.params.selectedAcId;
    console.log(this.selectedAcDetailId);
    this.$api.getApplicationCaseDetail({
      id: this.selectedAcDetailId
    }).then(res => {
      this.applicationCase = res.data;
      console.log(this.applicationCase);
      this.showPage = true;
    }).catch(error => {
      console.log(error.response);
    });
  }

};