export default {
  name: "CompanyProfileComponent",

  data() {
    return {
      showPage: false,
      companyProfileList: []
    };
  },

  mounted() {
    //获取公司简介内容
    this.$api.getComPanyProfileList().then(res => {
      console.log(res.data);
      this.companyProfileList = res.data[0];
      this.showPage = true; // console.log(res.data)
    }).catch(error => {
      console.log(error.response);
    });
  }

};