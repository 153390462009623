/**
 * 网络请求：Axios
 */

import axios from "axios";
import qs from "query-string"

//错误信息的响应方法
const errorHandle = (status,other) =>{
    switch (status){
        case 400:
            //请求头和服务器的限制
            console.log("服务器不理解请求的语法");
            break;
        case 401:
            //token验证失败，用户身份验证失败
            console.log("（未授权） 请求要求身份验证");
            break;
        case 403:
            //用户身份过期了，服务器请求限制
            console.log("（禁止） 服务器拒绝请求");
            break;
        case 404:
            //网络请求地址错误
            console.log("（未找到） 服务器找不到请求的网页");
            break;
        default:
            console.log(other);
            break;

    }
}




//创建axios对象
const intance = axios.create({
    timeout:5000 //请求超时
})

//全局配置
// intance.defaults.baseURL = "http://iwenwiki.com";
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// axios.defaults.headers.post['Content-Type'] = 'application/json';
// axios.defaults.headers.post
axios.defaults.headers.post['Content-Type'] = 'application/json';

//创建请求拦截和响应拦截
intance.interceptors.request.use(
    config=>{
        //配置
        if(config.method === 'post'){
            config.data = qs.stringify(config.data);
        }
        return config;
    },
    error => {
        //失败
        return Promise.reject(error);//返回失败信息
    }
);

intance.interceptors.response.use(
    //成功
    response=>{
        return response.status === 200 ? Promise.resolve(response) : Promise.reject(response);
    },
    error => {
        const {response} = error ;
        /**
         * response包含信息：
         * status
         * data
         */

        if(response){
            //给出开发者具体错误信息
            errorHandle(response.status,response.data);
            return Promise.reject(response);
        }else{
            console.log("请求中断或断网了");
        }
    }
)

export default intance;