import CommonImg from "@/components/CommonImg";
import CompanyHistoryComponent from "@/components/pc/CompanyHistoryComponent";
export default {
  name: "CompanyHistory",
  components: {
    CommonImg,
    CompanyHistoryComponent
  },

  data() {
    return {};
  }

};