export default {
  name: "Login",

  data() {
    return {
      username: "",
      password: ""
    };
  },

  methods: {
    loginHandle() {
      //登录流程
      this.$api.getLogin({
        username: this.username,
        password: this.password
      }).then(res => {
        console.log(res.data);
        localStorage.setItem("token", res.data.token);
      });
    },

    logoutHandle() {
      // localStorage.clear();
      localStorage.removeItem("token");
      this.$router.push("/");
    }

  }
};