
import Layout from "@/views/mobile/Layout";
import Home from "@/views/mobile/Home";
import AboutUs from "@/views/mobile/AboutUs";
import ProductCenter from "@/views/mobile/ProductCenter";
import ProductDetail from "@/views/mobile/ProductDetail";
import ApplicationCase from "@/views/mobile/ApplicationCase";
import ApplicationCaseDetail from "@/views/mobile/ApplicationCaseDetail";
import ContactUs from "@/views/mobile/ContactUs";
import OnlineInquiry from "@/views/mobile/OnlineInquiry";


const routerM =[
    {
        path: '/',
        name: 'Layout',
        component: Layout,
        children:[
            //  首页
            {
                path: '/home',
                name: 'home',
                component: Home
            },
            //  关于我们
            {
                path: '/aboutUs',
                name: 'aboutUs',
                component: AboutUs
            },
            //  产品中心
            {
                path: '/productCenter',
                name: 'productCenter',
                component: ProductCenter
            },
            //  产品详情
            {
                path: '/productDetail/:selectedProductIdM',
                name: 'productDetail',
                component: ProductDetail
            },
            //应用案例
            {
                path: '/applicationCase',
                name: 'applicationCase',
                component: ApplicationCase
            },
            //应用案例详情
            {
                path: '/acDetail/:selectedCaseIdM',
                name: 'acDetail',
                component: ApplicationCaseDetail
            },

            //联系我们
            {
                path: '/contactUs',
                name: 'contactUs',
                component: ContactUs
            },
            //在线询价
            {
                path: '/onlineInquiry',
                name: 'onlineInquiry',
                component: OnlineInquiry
            },
        ],


    }
]
// const routerM = [
//     {
//         path: '/',
//         name: 'Layout',
//         component: Layout,
//         children: [
//             //  首页
//             {
//                 path: '/home',
//                 name: 'home',
//                 component: Home
//             },
//             // ========== 关于我们 ===========
//             //  公司简介
//             {
//                 path: '/companyProfile',
//                 name: 'companyProfile',
//                 component: CompanyProfile
//             },
//             //  公司历程
//             {
//                 path: '/companyHistory',
//                 name: 'companyHistory',
//                 component: CompanyHistory
//             },
//             //  荣誉资质
//             {
//                 path: '/honorCertificate',
//                 name: 'honorCertificate',
//                 component: HonorCertificate,
//                 children:[
//                     // 荣誉资质首页
//                     {
//                         path: 'hcInfo',
//                         name: 'hcInfo',
//                         component: HonorCertificateComponent
//                     },
//
//                     // 荣誉资质详情
//                     {
//                         path: 'hcDetail',
//                         name: 'hcDetail',
//                         component: HonorCertificateDetailComponent
//                     },
//                 ]
//             },
//             // ==========关于我们end============
//             //  产品中心
//             {
//                 path: '/productCenter',
//                 name: 'productCenter',
//                 component: ProductCenter,
//                 children:[
//                     // 产品简介
//                     {
//                         path: 'pcInfo',
//                         name: 'pcInfo',
//                         component: ProductCenterComponent
//                     },
//                     // 产品详情
//                     {
//                         path: 'pcDetail',
//                         name: 'pcDetail',
//                         component: ProductDetailCenterComponent
//                     },
//                 ]
//             },
//
//             //  应用案例
//             {
//                 path: '/applicationCase',
//                 name: 'applicationCase',
//                 component: ApplicationCase,
//                 children: [
//                     // 案例简介
//                     {
//                         path: 'acInfo',
//                         name: 'acInfo',
//                         component: ApplicationCaseComponent
//                     },
//                     // 案例详情
//                     {
//                         path: 'acDetail',
//                         name: 'acDetail',
//                         component: ApplicationCaseDetailComponent
//                     },
//                 ]
//             },
//             //  联系我们
//             {
//                 path: '/contactUs',
//                 name: 'contactUs',
//                 component: ContactUs
//             },
//             //  在线询价
//             {
//                 path: '/onlineInquiry',
//                 name: 'onlineInquiry',
//                 component: OnlineInquiry
//             },
//         ]
//     },
// ]

export default routerM