export default {
  name: "CompanyHistoryComponent",

  data() {
    return {
      showPage: false,
      companyHistoryList: []
    };
  },

  created() {
    //获取公司历程数据
    this.$api.getCompanyHistoryList().then(res => {
      console.log(res.data);
      this.companyHistoryList = res.data;
      this.showPage = true; // console.log(res.data)
    }).catch(error => {
      console.log(error.response);
    });
  }

};