/**
 * 存储全部路径
 */

const base = {
    // baseURL:"http://localhost:8000",
    baseURL:"http://118.195.198.82:8000",
    // baseURL:"/api",
    login:"/login",
    //轮播图接口
    banner:"/banner/",
    //企业简介接口
    companyProfile:"/companyProfile/",
    //企业历程接口
    companyHistory:"/companyHistory/",
    //荣誉资质接口
    honorCertification:"/honorCertification/",
    //应用案例接口
    applicationCase:"/ApplicationCase/",

    //联系我们接口
    contactUs:"/ContactUs/",

    //
    onlineInquiry:"/OnlineInquiry/",

    //
    productCategory:"/productCategory/",

    productCenter:"/productCenter/",

    getProductByCategory:"/getProductByCategory/",

    getProductIntroduceByPIdList:"/productIntroduce/",
}
export default base;