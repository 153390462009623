import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-52cb4046"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  key: 0,
  style: {
    "margin-top": "1rem",
    "margin-bottom": "2rem"
  }
};
const _hoisted_2 = {
  style: {
    "padding-left": "40px",
    "padding-right": "40px"
  }
};
const _hoisted_3 = {
  style: {
    "font-size": "22px",
    "font-weight": "600",
    "font-family": "微软雅黑",
    "color": "#595959"
  }
};
const _hoisted_4 = {
  style: {
    "padding-left": "40px",
    "padding-right": "40px",
    "margin-top": "30px",
    "margin-bottom": "40px"
  }
};
const _hoisted_5 = {
  style: {
    "padding-left": "40px",
    "padding-right": "40px"
  }
};
const _hoisted_6 = {
  style: {
    "margin-top": "15px",
    "margin-bottom": "15px",
    "line-height": "1.6",
    "font-size": "14px",
    "font-family": "微软雅黑"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CommonImgM = _resolveComponent("CommonImgM");

  const _component_van_nav_bar = _resolveComponent("van-nav-bar");

  const _component_van_image = _resolveComponent("van-image");

  const _component_van_divider = _resolveComponent("van-divider");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_CommonImgM), _createVNode(_component_van_nav_bar, {
    title: "",
    "left-text": "返回",
    "left-arrow": "",
    onClickLeft: $options.onClickLeft,
    style: {
      "font-size": "24px"
    }
  }, null, 8, ["onClickLeft"]), $data.showPage ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(this.applicationCase.name), 1)]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_van_image, {
    src: this.applicationCase.image_url
  }, null, 8, ["src"])]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_van_divider, {
    style: {
      borderColor: '#9e9e9e'
    }
  }), _createElementVNode("div", _hoisted_6, _toDisplayString(this.applicationCase.detail), 1), _createVNode(_component_van_divider, {
    style: {
      borderColor: '#9e9e9e'
    }
  })])])) : _createCommentVNode("", true)], 64);
}