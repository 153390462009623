import CommonImgM from "@/components/mobile/commonImgM";
export default {
  name: "ContactUs",
  components: {
    CommonImgM
  },

  data() {
    return {
      showPage: false,
      // map:""
      MapPoint: {
        lng: 117.633443,
        lat: 35.530016
      },
      markerPoint: {
        lng: 117.635696,
        lat: 35.533379
      },
      contactUS: []
    };
  },

  methods: {},

  mounted() {
    //获取数据
    this.$api.getContactUsList().then(res => {
      this.contactUS = res.data[0]; // console.log("===============")

      console.log(res.data);
      this.showPage = true;
    }).catch(error => {
      console.log(error.response);
    });
  }

};