import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_tab = _resolveComponent("van-tab");

  const _component_van_tabs = _resolveComponent("van-tabs");

  const _component_router_view = _resolveComponent("router-view");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_tabs, {
    active: $data.active,
    "onUpdate:active": _cache[0] || (_cache[0] = $event => $data.active = $event),
    class: "my_tabs_nav",
    animated: true,
    sticky: "",
    onClickTab: $options.mySelectNav
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.myNav, (item, index) => {
      return _openBlock(), _createBlock(_component_van_tab, {
        title: item.title,
        to: item.path,
        key: index,
        name: item.path
      }, null, 8, ["title", "to", "name"]);
    }), 128))]),
    _: 1
  }, 8, ["active", "onClickTab"]), _createVNode(_component_router_view)], 64);
}