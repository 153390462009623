import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-a8878050"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  key: 0,
  class: "AcDetailGrid"
};
const _hoisted_2 = {
  style: {
    "text-align": "left"
  }
};
const _hoisted_3 = {
  style: {
    "font-family": "微软雅黑",
    "font-size": "40px",
    "color": "#595959"
  }
};
const _hoisted_4 = {
  class: "application-case-content-grid"
};
const _hoisted_5 = {
  class: "application-case-content"
};
const _hoisted_6 = {
  style: {
    "margin-left": "auto",
    "margin-right": "auto"
  }
};
const _hoisted_7 = {
  class: "image-slot"
};
const _hoisted_8 = {
  class: "image-slot"
};
const _hoisted_9 = {
  style: {
    "color": "#595959",
    "line-height": "1.4",
    "margin-top": "20px",
    "margin-bottom": "20px",
    "font-size": "24px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_icon_picture = _resolveComponent("icon-picture");

  const _component_el_icon = _resolveComponent("el-icon");

  const _component_el_image = _resolveComponent("el-image");

  const _component_el_divider = _resolveComponent("el-divider");

  return _openBlock(), _createElementBlock(_Fragment, null, [$data.showPage ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("span", null, [_createElementVNode("strong", null, [_createElementVNode("span", _hoisted_3, _toDisplayString(this.applicationCase.name), 1)])])])])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createVNode(_component_el_image, {
    src: this.applicationCase.image_url,
    style: {
      "margin-bottom": "30px"
    }
  }, {
    placeholder: _withCtx(() => [_createElementVNode("div", _hoisted_7, [_createVNode(_component_el_icon, null, {
      default: _withCtx(() => [_createVNode(_component_icon_picture)]),
      _: 1
    })])]),
    error: _withCtx(() => [_createElementVNode("div", _hoisted_8, [_createVNode(_component_el_icon, null, {
      default: _withCtx(() => [_createVNode(_component_icon_picture)]),
      _: 1
    })])]),
    _: 1
  }, 8, ["src"])]), _createVNode(_component_el_divider), _createElementVNode("div", _hoisted_9, _toDisplayString(this.applicationCase.detail), 1), _createVNode(_component_el_divider)])])], 64);
}