import Layout from "@/views/pc/Layout";
import Home from "@/views/pc/Home";
import CompanyProfile from "@/views/pc/CompanyProfile";
import CompanyHistory from "@/views/pc/CompanyHistory";
import HonorCertificate from "@/views/pc/HonorCertificate";
import HonorCertificateComponent from "@/components/pc/HonorCertificateComponent";
import HonorCertificateDetailComponent from "@/components/pc/HonorCertificateDetailComponent";
import ProductCenter from "@/views/pc/ProductCenter";
import ProductCenterComponent from "@/components/pc/ProductCenterComponent";
import ProductDetailCenterComponent from "@/components/pc/ProductDetailCenterComponent";
import ApplicationCase from "@/views/pc/ApplicationCase";
import ApplicationCaseComponent from "@/components/pc/ApplicationCaseComponent";
import ApplicationCaseDetailComponent from "@/components/pc/ApplicationCaseDetailComponent";
import ContactUs from "@/views/pc/ContactUs";
import OnlineInquiry from "@/views/pc/OnlineInquiry";
import Login from "@/views/Login";

const routerPC = [

    {
        path: '/',
        name: 'Layout',
        component: Layout,
        children: [
            //  首页
            {
                path: '/home',
                name: 'home',
                component: Home
            },
            // ========== 关于我们 ===========
            //  公司简介
            {
                path: '/companyProfile',
                name: 'companyProfile',
                component: CompanyProfile
            },
            //  公司历程
            {
                path: '/companyHistory',
                name: 'companyHistory',
                component: CompanyHistory
            },
            //  荣誉资质
            {
                path: '/honorCertificate',
                name: 'honorCertificate',
                component: HonorCertificate,
                children:[
                    // 荣誉资质首页
                    {
                        path: 'hcInfo',
                        name: 'hcInfo',
                        component: HonorCertificateComponent
                    },

                    // 荣誉资质详情
                    {
                        path: 'hcDetail/:selectedImageId',
                        name: 'hcDetail',
                        component: HonorCertificateDetailComponent
                    },
                ]
            },
            // ==========关于我们end============
            //  产品中心
            {
                path: '/productCenter',
                name: 'productCenter',
                component: ProductCenter,
                children:[
                    // 产品简介
                    {
                        path: 'pcInfo',
                        name: 'pcInfo',
                        component: ProductCenterComponent
                    },
                    // 产品详情
                    {
                        path: 'pcDetail/:selectedProductId',
                        name: 'pcDetail',
                        component: ProductDetailCenterComponent
                    },
                ]
            },

            //  应用案例
            {
                path: '/applicationCase',
                name: 'applicationCase',
                component: ApplicationCase,
                children: [
                    // 案例简介
                    {
                        path: 'acInfo',
                        name: 'acInfo',
                        component: ApplicationCaseComponent
                    },
                    // 案例详情
                    {
                        path: 'acDetail/:selectedAcId',
                        name: 'acDetail',
                        component: ApplicationCaseDetailComponent
                    },
                ]
            },
            //  联系我们
            {
                path: '/contactUs',
                name: 'contactUs',
                component: ContactUs
            },
            //  在线询价
            {
                path: '/onlineInquiry',
                name: 'onlineInquiry',
                component: OnlineInquiry
            },
        ]
    },
//   //  首页
//   {
//     path: '/',
//     name: 'home',
//     component: Home
//   },
//   // ========== 关于我们 ===========
//   //  公司简介
//   {
//     path: '/companyProfile',
//     name: 'companyProfile',
//     component: CompanyProfile
//   },
//   //  公司历程
//   {
//     path: '/companyHistory',
//     name: 'companyHistory',
//     component: CompanyHistory
//   },//  荣誉资质
//   {
//     path: '/honorCertificate',
//     name: 'honorCertificate',
//     component: HonorCertificate
//   },
// // ================================
//   //  产品中心
//   {
//     path: '/productCenter',
//     name: 'productCenter',
//     component: productCenter
//   },
//
//   //  应用案例
//   {
//     path: '/applicationCase',
//     name: 'applicationCase',
//     component: ApplicationCase
//   },
//   //  联系我们
//   {
//     path: '/contactUs',
//     name: 'contactUs',
//     component: ContactUs
//   },
//   //  在线询价
//   {
//     path: '/onlineInquiry',
//     name: 'onlineInquiry',
//     component: OnlineInquiry
//   },


    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/about',
        name: 'about',
        meta:{
            isLogin:true
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    }
]

export default routerPC