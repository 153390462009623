export default {
  name: "CompanyProfileComponentM",

  data() {
    return {
      showPage: false,
      companyProfileUrl: require("@/assets/common/companyImage.png"),
      companyProfileList: []
    };
  },

  mounted() {
    this.$api.getComPanyProfileList().then(res => {
      console.log(res.data);
      this.companyProfileList = res.data[0];
      this.showPage = true; // console.log(res.data)
    }).catch(error => {
      console.log(error.response);
    });
  }

};