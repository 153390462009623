import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-b6bf259a"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  key: 0,
  class: "honor-certificate-content-grid"
};
const _hoisted_2 = {
  class: "honor-certificate-content"
};
const _hoisted_3 = {
  style: {
    "text-align": "center",
    "color": "#333333",
    "margin-top": "20px",
    "margin-bottom": "35px",
    "font-family": "微软雅黑",
    "font-size": "35px",
    "font-weight": "600"
  }
};
const _hoisted_4 = {
  class: "image-slot"
};
const _hoisted_5 = {
  class: "image-slot"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_icon_picture = _resolveComponent("icon-picture");

  const _component_el_icon = _resolveComponent("el-icon");

  const _component_el_image = _resolveComponent("el-image");

  return $data.showPage ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(this.honorCertificationDetail.name), 1), _createElementVNode("div", null, [_createVNode(_component_el_image, {
    src: this.honorCertificationDetail.image_url
  }, {
    placeholder: _withCtx(() => [_createElementVNode("div", _hoisted_4, [_createVNode(_component_el_icon, null, {
      default: _withCtx(() => [_createVNode(_component_icon_picture)]),
      _: 1
    })])]),
    error: _withCtx(() => [_createElementVNode("div", _hoisted_5, [_createVNode(_component_el_icon, null, {
      default: _withCtx(() => [_createVNode(_component_icon_picture)]),
      _: 1
    })])]),
    _: 1
  }, 8, ["src"])])])])) : _createCommentVNode("", true);
}