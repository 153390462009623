import { Picture as IconPicture } from '@element-plus/icons-vue';
export default {
  name: "ApplicationCaseComponent",
  components: {
    IconPicture
  },

  data() {
    return {
      showPage: false,
      ApplicationCaseList: [],
      totalData: null,
      currentPage: 1,
      pageSize: 3,
      list: []
    };
  },

  methods: {
    lookDetail(selectedId) {
      this.$router.push({
        name: "acDetail",
        params: {
          selectedAcId: selectedId
        }
      });
    },

    handleCurrentChange(cpag) {
      this.currentPage = cpag;
      this.list = this.ApplicationCaseList.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize);
    }

  },
  computed: {
    sliceList() {
      return function (imageUrlText, number) {
        let index = 0;
        let newArray = [];

        while (index < imageUrlText.length) {
          newArray.push(imageUrlText.slice(index, index += number));
        }

        return newArray;
      };
    }

  },

  async created() {
    //获取数据
    await this.$api.getApplicationCaseList().then(res => {
      this.ApplicationCaseList = res.data;
      this.totalData = this.ApplicationCaseList.length;
      this.handleCurrentChange(this.currentPage);
      this.showPage = true;
      console.log(this.showPage);
    }).catch(error => {
      console.log(error.response);
    });
    console.log(this.showPage);
  }

};