import CommonImg from "@/components/CommonImg";
import HonorCertificateComponent from "@/components/pc/HonorCertificateComponent";
export default {
  name: "HonorCertificate",
  components: {
    CommonImg,
    HonorCertificateComponent
  },

  created() {},

  mounted() {}

};