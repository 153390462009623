import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  style: {
    "flex": "0 0 auto"
  }
};
const _hoisted_2 = {
  style: {
    "flex": "1 0 auto"
  }
};
const _hoisted_3 = {
  style: {
    "flex": "0 0 auto"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_HeaderM = _resolveComponent("HeaderM");

  const _component_Nav = _resolveComponent("Nav");

  const _component_FooterM = _resolveComponent("FooterM");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_HeaderM)]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_Nav)]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_FooterM)])], 64);
}