export default {
  name: "CompanyHistoryComponentM",

  data() {
    return {
      showPage: false,
      companyHistoryList: ""
    };
  },

  mounted() {
    //获取数据
    this.$api.getCompanyHistoryList().then(res => {
      console.log(res.data);
      this.companyHistoryList = res.data; // setTimeout(function (){
      //
      // },2000)

      this.showPage = true; // console.log(res.data)
    }).catch(error => {
      console.log(error.response);
    });
  }

};