import HeaderM from "@/components/mobile/headerM";
import Nav from "@/components/mobile/nav";
import FooterM from "@/components/mobile/FooterM";
export default {
  name: "Layout",
  components: {
    FooterM,
    HeaderM,
    Nav
  }
};