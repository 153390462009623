import CompanyProfileComponentM from "@/components/mobile/CompanyProfileComponentM";
import ApplicationCaseComponentM from "@/components/mobile/ApplicationCaseComponentM";
export default {
  name: "Home",
  inject: ['reload'],
  components: {
    CompanyProfileComponentM,
    ApplicationCaseComponentM
  },

  data() {
    return {
      imgArray: [require('@/assets/home/1.jpg'), require('@/assets/home/2.jpg'), require('@/assets/home/3.jpg')],
      showPage: false,
      ProductCategoryList: []
    };
  },

  methods: {
    lookDetail(selectedId) {
      this.$router.push({
        name: "productCenter",
        params: {
          selectedCategoryIdM: selectedId
        }
      });
      this.$mybus.emit('isProduct', true); // this.reload();
    }

  },

  mounted() {
    console.log("mountedHome"); //获取产品种类

    this.$api.getProductCategoryList().then(res => {
      this.ProductCategoryList = res.data; // console.log("===============")

      this.showPage = true; // setTimeout(function (){
      //   alert("msg")
      // },1000)

      console.log(res.data);
    }).catch(error => {
      console.log(error.response);
    });
  }

};