export default {
  name: "HeaderNav",

  data() {
    return {
      activeIndex: "1"
    };
  },

  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    }

  },

  created() {
    // this.$route.path = '/home';
    console.log("created");
    console.log(this.$route.path); //自动跳转到首页

    if (this.$route.path === '/') {
      this.$router.replace("/home");
    }
  }

};