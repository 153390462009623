import querystring from 'query-string';
import base from "@/api/base";
import { ElMessage } from 'element-plus';
export default {
  name: "OnlineInquiryComponent",

  data() {
    return {
      InquiryForm: {
        name: "",
        company: "",
        telephone: "",
        product: "",
        remark: ""
      },
      rules: {
        name: [{
          required: true,
          message: "姓名不能为空",
          trigger: "blur"
        }],
        company: [{
          required: false,
          message: "公司名名不能为空",
          trigger: "blur"
        }],
        telephone: [{
          required: true,
          message: "手机号不能为空",
          trigger: "blur"
        }, {
          min: 7,
          max: 11,
          message: '长度应该在7到11之间',
          trigger: 'blur'
        } // { required:true, type: 'number', message: '必须为数字' },
        ],
        product: [{
          required: true,
          message: "意向产品不能为空",
          trigger: "blur"
        }]
      },
      dialogVisible: false
    };
  },

  methods: {
    onSubmit() {
      console.log(this.InquiryForm);
      this.dialogVisible = true;
      console.log("dia");
    },

    onReset() {
      this.$refs.formRef.resetFields();
    },

    submitData() {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          //axios路由
          this.$api.postOnlineInquiry(this.InquiryForm).then(res => {
            console.log("=========");
            console.log(res);
          }).catch(error => {
            console.log(error.response);
          });
          ElMessage({
            message: '提交成功',
            type: 'success'
          });
          this.$refs.formRef.resetFields();
        } else {
          ElMessage({
            message: '请检查您填写的表单内容',
            type: 'error'
          }); // return false
        }
      }); // this.$axios({
      //   method:'post',
      //   url: base.baseURL+base.onlineInquiry,
      //   data:this.InquiryForm
      // }
      // )

      this.dialogVisible = false;
    }

  }
};