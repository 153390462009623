export default {
  name: "HonorCertificateComponent",

  data() {
    return {
      showPage: false,
      honorCertificationList: [],
      totalData: null,
      currentPage: 1,
      pageSize: 3,
      list: []
    };
  },

  methods: {
    lookDetail(selectedId) {
      this.$router.push({
        name: "hcDetail",
        params: {
          selectedImageId: selectedId
        }
      });
    },

    handleCurrentChange(cpag) {
      this.currentPage = cpag;
      this.list = this.honorCertificationList.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize);
    }

  },
  computed: {
    sliceList() {
      return function (imageUrlText, number) {
        let index = 0;
        let newArray = [];

        while (index < imageUrlText.length) {
          newArray.push(imageUrlText.slice(index, index += number));
        }

        return newArray;
      };
    }

  },

  mounted() {
    //获取数据
    this.$api.getHonorCertificationList().then(res => {
      console.log(res.data);
      this.honorCertificationList = res.data;
      this.totalData = this.honorCertificationList.length;
      this.handleCurrentChange(this.currentPage);
      this.showPage = true;
    }).catch(error => {
      console.log(error.response);
    });
  }

};