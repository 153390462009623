import { onBeforeMount } from "vue";
export default {
  name: "ProductCenterComponentM",

  data() {
    return {
      showPage: false,
      showPagination: true,
      active: 0,
      selectedCategoryIdM: null,
      //选中的种类id名称
      ProductCategoryList: [],
      ProductCenterList: [],
      ProductAll: [],
      //暂存总产品
      Product: [],
      totalData: '',
      currentPage: 1,
      pageSize: 5,
      //每页展示数据数量
      list: []
    };
  },

  methods: {
    selectAllProduct() {
      this.ProductCenterList = this.ProductAll;
      this.totalData = this.ProductCenterList.length;
      this.handleCurrentChange(1);

      if (this.totalData <= this.pageSize) {
        this.showPagination = false;
      } else {
        this.showPagination = true;
      }

      console.log(this.ProductCenterList);
    },

    lookDetail(selectedId) {
      this.$router.push({
        name: "productDetail",
        params: {
          selectedProductIdM: selectedId
        }
      });
    },

    //根据选择的种类获取
    getProductByCategory(categoryId) {
      this.Product = [];
      let myData = JSON.parse(JSON.stringify(this.ProductAll));

      for (let i = 0; i < myData.length; i++) {
        if (myData[i].category == categoryId) {
          this.Product.push(myData[i]);
        }
      } // console.log("======sss=========")
      // console.log(this.Product)


      this.ProductCenterList = this.Product;
      this.totalData = this.ProductCenterList.length;
      this.handleCurrentChange(1); //只有一页数据时不显示分页

      if (this.totalData <= this.pageSize) {
        this.showPagination = false;
      } else {
        this.showPagination = true;
      }
    },

    //分页
    handleCurrentChange(cpag) {
      this.currentPage = cpag;
      this.list = this.ProductCenterList.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize);
    }

  },

  async mounted() {
    //获取产品种类
    await this.$api.getProductCategoryList().then(res => {
      this.ProductCategoryList = res.data; // console.log("===============")

      console.log(res.data);
    }).catch(error => {
      console.log(error.response);
    }); //获取产品种类id

    this.selectedCategoryIdM = this.$route.params.selectedCategoryIdM;

    if (this.selectedCategoryIdM) {
      //获取指定种类产品
      this.$api.getProductCenterList().then(res => {
        this.ProductCenterList = res.data;
        this.ProductAll = res.data;
        console.log("===============");
        console.log(res.data); // this.list = this.ApplicationCaseList

        this.totalData = this.ProductCenterList.length; // this.handleCurrentChange(this.currentPage)

        this.getProductByCategory(this.selectedCategoryIdM);

        for (let i = 0; i < this.ProductCategoryList.length; i++) {
          console.log("-----sss------");

          if (this.ProductCategoryList[i].id == this.selectedCategoryIdM) {
            console.log("-----ss------");
            this.active = i + 1;
            break;
          }
        } //只有一页数据时不显示分页


        if (this.totalData <= this.pageSize) {
          this.showPagination = false;
        }

        this.showPage = true;
      }).catch(error => {
        console.log(error.response);
      });
    } else {
      //获取全部产品
      this.$api.getProductCenterList().then(res => {
        this.ProductCenterList = res.data;
        this.ProductAll = res.data; // console.log("===============")

        console.log(res.data); // this.list = this.ApplicationCaseList

        this.totalData = this.ProductCenterList.length;
        this.handleCurrentChange(this.currentPage); //只有一页数据时不显示分页

        if (this.totalData <= this.pageSize) {
          this.showPagination = false;
        }

        this.showPage = true;
      }).catch(error => {
        console.log(error.response);
      });
    }
  }

};